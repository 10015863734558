<template>
	<div>
		<v-row dense class="px-3" style="max-height: 96px; overflow-y: scroll">
			<v-col cols="12" sm="4">
				<v-autocomplete
					:label="$t('form.pmdatasourcetype')"
					toplabel
					clearable
					required
					v-model="selectedDatasource"
					:items="datasources"
					item-text="name"
					return-object
					hide-details
					@input="inputDatasource"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="4">
				<v-autocomplete
					:label="$t('common.devices')"
					toplabel
					clearable
					required
					v-model="selectedDevices"
					:items="devicesOptions"
					:disabled="disabledDevices"
					multiple
					item-text="name"
					return-object
					hide-details
					@blur="inputDevice"
				>
					<template v-slot:prepend-item>
						<v-list-item
							ripple
							@mousedown.prevent
							@click="toggleSelection('selectedDevices', 'devicesOptions', selectedDevices.length == devicesOptions.length)"
						>
							<!-- @click="toggleSelection('selectedDevices', 'devicesOptions', selectedDevices.length == devicesOptions.length)" -->
							<v-list-item-action>
								<v-icon :color="selectedDevices.length > 0 ? primaryColor : ''">
									{{ icon(selectedDevices.length, devicesOptions.length) }}
								</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									{{
										selectedDevices.length == devicesOptions.length
											? $t('panels.device.unselect-all')
											: $t('panels.device.select-all')
									}}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="mt-2"></v-divider>
					</template>
					<template v-slot:selection="{ item, index }">
						<v-chip v-if="index < 2" small>
							<span>{{ item.name }}</span>
						</v-chip>
						<span v-if="index == 2" class="grey--text text-caption"> (+{{ selectedDevices.length - 2 }} others) </span>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="4">
				<v-autocomplete
					:label="$t('aqi.pollutant')"
					toplabel
					clearable
					required
					multiple
					:disabled="disabledDevices"
					v-model="selectedPollutants"
					:items="pollutantsOptions"
					item-text="name"
					return-object
					:hide-details="true"
					class="px-1"
				>
					<template v-slot:prepend-item>
						<v-list-item
							ripple
							@mousedown.prevent
							@click="toggleSelection('selectedPollutants', 'pollutantsOptions', selectedPollutants.length == pollutantsOptions.length)"
						>
							<v-list-item-action>
								<v-icon :color="selectedPollutants.length > 0 ? primaryColor : ''">
									{{ icon(selectedPollutants.length, pollutantsOptions.length) }}
								</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>
									{{
										selectedPollutants.length == pollutantsOptions.length
											? $t('panels.device.unselect-all')
											: $t('panels.device.select-all')
									}}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="mt-2"></v-divider>
					</template>
					<template v-slot:selection="{ item, index }">
						<v-chip v-if="index === 0" small>
							<span
								>{{ item.name
								}}<!--  {{ item.symbol ? '(' + item.symbol + ')' : '' }} --></span
							>
						</v-chip>
						<span v-if="index === 1" class="grey--text text-caption"> (+{{ selectedPollutants.length - 1 }} others) </span>
					</template>
					<!-- <template v-slot:item="{ item }">
						<div class="v-list-item__content">
							<div class="v-list-item__title">{{ item.name }} {{ item.symbol ? '(' + item.symbol + ')' : '' }}</div>
						</div>
					</template> -->
				</v-autocomplete>
			</v-col>
		</v-row>
		<div class="pa-0 device-realtime width-100 overflow-x-hidden" :style="fullscreenDialog ? 'height: calc(100vh - 100px)' : chartStyle">
			<v-row v-if="selectedDevices.length > 0 && selectedPollutants.length > 0" no-gutters>
				<v-col
					:xl="getBreakPoint(selectedDevices.length, 'xl')"
					:lg="getBreakPoint(selectedDevices.length, 'lg')"
					:md="getBreakPoint(selectedDevices.length, 'md')"
					:sm="getBreakPoint(selectedDevices.length, 'sm')"
					cols="12"
					v-for="(device, i) in selectedDevices"
					:key="i"
					class="pa-0"
				>
					<v-card flat class="pa-0">
						<v-card-title class="px-3 pt-1 pb-0">{{ device.code }}</v-card-title>
						<v-card-text class="px-2 pb-2 pt-0">
							<v-row v-if="pollutants" no-gutters>
								<v-col
									cols="12"
									v-for="pollutant in pollutants"
									:key="pollutant.acronym"
									:value="pollutant.acronym"
									:title="pollutant.name"
									class="pa-0"
									style="max-height: 320px"
								>
									<v-card elevation="2" outlined class="my-2 d-flex flex-wrap flex-column align-center justify-center">
										<v-tooltip
											v-if="pollutant.name.length > 20 && getErrors(pollutant.elasticacronym, device.code).length == 0"
											top
											max-width="500"
											color="var(--gris-secondary)"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-card-title
													:color="primaryColor"
													dark
													v-bind="attrs"
													v-on="on"
													class="d-flex justify-center align-center pa-0 pt-2"
													style="cursor: help; flex: 1 1 100%"
												>
													<span class="fw-semi fs-md">{{ pollutant.acronym }}</span>
												</v-card-title>
											</template>
											<span class="fs-regular">{{ pollutant.name }}</span>
										</v-tooltip>
										<v-tooltip
											v-else-if="pollutant.name.length <= 22 && getErrors(pollutant.elasticacronym, device.code).length > 0"
											top
											max-width="360"
											color="var(--gris-secondary)"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-card-title
													:color="primaryColor"
													dark
													v-bind="attrs"
													v-on="on"
													class="d-flex justify-center align-center pa-0 pt-2"
													style="cursor: help; flex: 1 1 100%"
												>
													<span class="fw-semi fs-md"
														>{{ pollutant.name }}
														<i
															class="fak fa-solid-alert ps-1 fs-sm fw-regular"
															style="color: var(--rojo-100) !important"
														></i>
													</span>
												</v-card-title>
											</template>
											<ul v-if="getErrors(pollutant.elasticacronym, device.code).length > 1">
												<li
													v-for="(error, i) in getErrors(pollutant.elasticacronym, device.code)"
													:key="i"
													class="fw-500 fs-regular text-center"
												>
													{{ error.message }}
												</li>
											</ul>
											<span v-else class="fs-regular">{{ getErrors(pollutant.elasticacronym, device.code)[0].message }}</span>
										</v-tooltip>
										<v-card-title
											v-else-if="pollutant.name.length > 20 && getErrors(pollutant.elasticacronym, device.code).length > 0"
											:color="primaryColor"
											dark
											class="d-flex justify-center align-center pa-0 pt-2"
											style="cursor: help; flex: 1 1 100%"
										>
											<v-tooltip top max-width="350" color="var(--gris-secondary)">
												<template v-slot:activator="{ on, attrs }">
													<span class="fw-semi fs-md" v-bind="attrs" v-on="on">{{ pollutant.acronym }}</span>
												</template>
												<span class="fs-regular">{{ pollutant.name }}</span>
											</v-tooltip>

											<v-tooltip top max-width="360" color="var(--gris-secondary)">
												<template v-slot:activator="{ on, attrs }">
													<i
														class="fak fa-solid-alert ps-2 fs-sm fw-regular"
														style="color: var(--rojo-100) !important"
														v-bind="attrs"
														v-on="on"
													></i>
												</template>
												<ul v-if="getErrors(pollutant.elasticacronym, device.code).length > 1">
													<li
														v-for="(error, i) in getErrors(pollutant.elasticacronym, device.code)"
														:key="i"
														class="fw-500 fs-regular text-center"
													>
														{{ error.message }}
													</li>
												</ul>
												<span v-else class="fs-regular">{{
													getErrors(pollutant.elasticacronym, device.code)[0].message
												}}</span>
											</v-tooltip>
										</v-card-title>
										<v-card-title
											v-else
											:color="primaryColor"
											dark
											class="d-flex justify-center align-center pa-0 pt-2"
											style="flex: 1 1 100%"
										>
											<span class="fw-semi fs-md">{{ pollutant.name }}</span>
										</v-card-title>
										<div :style="fullscreenDialog ? 'height: 200px; width: 150px' : 'height: 150px; width: 150px'">
											<gauge-real-time
												v-if="hasRealTimeChartData(pollutant.elasticacronym, device.code)"
												:pollutant="pollutant"
												:pollutantData="realTimeElasticData[device.code][pollutant.elasticacronym]"
												height="100%"
												width="100%"
												:lineWidth="10"
											></gauge-real-time>
											<div
												v-else
												class="switchoff d-flex flex-column align-center justify-center"
												style="height: 100%; min-width: 100%"
											>
												<v-icon size="1.5rem"> fal fa-inbox </v-icon>
												<div class="switchoff-text text-center">{{ $t('common.noRealTimeData') }}</div>
											</div>
										</div>

										<v-card-text
											v-if="hasRealTimeChartData(pollutant.elasticacronym, device.code)"
											class="d-flex justify-center flex-wrap px-2 pt-0 pb-2"
											style="flex: 1 1 100%"
										>
											<span> {{ $t('common.updatedRealTime') }} </span>
											<span class="fw-bold ml-1 text-no-wrap"> {{ dateObserved[device.code][pollutant.elasticacronym] }}</span>
										</v-card-text>
										<div v-else style="height: 30px"></div>
									</v-card>
								</v-col>
							</v-row>
							<v-row v-else class="switchoff height-100 d-flex flex-column align-center justify-center">
								<v-icon size="1.5rem"> fal fa-inbox </v-icon>
								<div class="switchoff-text">{{ $t('common.noData') }}</div>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import GaugeRealTime from '@/components/echart/GaugeRealTime.vue';
import { formatTimestamp, obtainIndexName, obtainRealTimeDatasource, sortKey } from '@/api/common';

import constants from '@/utils/constants';
export default {
	components: {
		GaugeRealTime
	},
	props: {},
	data() {
		return {
			dateObserved: {},
			primaryColor: this.$store.state.global.primaryColor,
			selectAll: { code: -1, name: this.$t('panels.device.select-all') },
			selectNone: { code: -1, name: this.$t('panels.device.unselect-all') },
			devicesOptions: [],
			pollutantsOptions: [],
			disabledDevices: true,
			selectedDatasource: null,
			selectedDevices: [],
			selectedPollutants: []
		};
	},
	computed: {
		pollutants() {
			if (this.selectedDatasource && this.selectedDevices.length > 0 && this.selectedPollutants.length > 0) {
				return this.selectedPollutants;
			}
			return [];
		},
		realTimeElasticData() {
			return this.$store.getters.getDatasourceRealTimeData;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		},
		devices() {
			const devicesData = this.$store.getters.getDevicesData;
			if (devicesData && devicesData.length > 0) {
				return devicesData;
			}
			return [];
		},
		datasources() {
			const datasourcetype = this.$store.getters.getDatasourcetype;
			if (datasourcetype && datasourcetype.length > 0) {
				return datasourcetype; /* .sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				}); */
			}
			return [];
		}
	},
	watch: {
		/* fullscreenDialog(val) {
			if (val && this.displayLocation == 'dialog') {
				this.chartStyle = { maxHeight: 'calc(100vh - 80px)', width: '100%' };
			} else {
				this.chartStyle =
					this.displayLocation == 'card' ? { maxHeight: '384px', width: '100%' } : { maxHeight: 'calc(80vh - 48px)', width: '100%' };
			}
		} */
		realTimeElasticData(val) {
			console.log('realTimeElasticData', val);
		}
	},
	created() {
		this.chartStyle = { height: 'calc(80vh - 32px - 70px)', width: '100%' };
	},
	mounted() {
		if (this.datasources.length == 1 && !this.selectedDatasource) {
			this.selectedDatasource = this.datasources[0];
			this.inputDatasource(this.selectedDatasource);
		}
	},
	updated() {
		console.warn('real time updated', this.devices, this.datasources, this.pollutants);
	},
	methods: {
		hasRealTimeChartData(elasticacronym, device) {
			console.log('hasRealTimeChartData', elasticacronym, device, this.realTimeElasticData);
			if (this.realTimeElasticData && this.realTimeElasticData[device]) {
				if (this.realTimeElasticData[device][elasticacronym]) {
					if (Object.hasOwn(this.realTimeElasticData[device][elasticacronym], elasticacronym)) {
						const timestamp = this.realTimeElasticData[device][elasticacronym][constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
						const timezone = this.realTimeElasticData[device][elasticacronym]['timezone'];
						if (!this.dateObserved[device]) this.dateObserved[device] = {};
						this.dateObserved[device][elasticacronym] = formatTimestamp(timestamp, this.userProperties, false, timezone);
						return true;
					}
					return false;
				}
			}
			return false;
		},
		getErrors(elasticacronym, device) {
			let error = false;
			if (this.realTimeElasticData[device]) {
				if (
					this.realTimeElasticData[device][elasticacronym] &&
					Object.hasOwn(this.realTimeElasticData[device][elasticacronym], 'validations')
				) {
					let parameterError = this.realTimeElasticData[device][elasticacronym].validations.filter(
						(validation) => validation.parameter == elasticacronym
					);
					if (parameterError) error = parameterError;
				}
			}
			console.log('getErrors', elasticacronym, device, this.realTimeElasticData, error);
			return error;
		},

		inputDatasource(datasourcetype) {
			console.log('inputDatasource', datasourcetype, this.selectedDatasource);
			if (datasourcetype) {
				this.devicesOptions = [];
				this.selectedDevices = [];
				this.selectedPollutants = [];
				this.disabledDevices = false;

				/* this.devicesOptions = this.devices.filter((device) => device.pmdatasourcetypeid == this.selectedDatasource[0].pmdatasourcetypeid);
				this.selectedDevices = [this.devicesOptions[0].code]; */
				this.devicesOptions = [];
				this.pollutantsOptions = [];

				this.pollutantsOptions = this.$store.getters.getParameters.filter(
					(pollutant) => pollutant.pmdatasourcetypeid == datasourcetype.pmdatasourcetypeid
				);
				this.devicesOptions = this.devices.filter((device) => device.pmdatasourcetypeid == datasourcetype.pmdatasourcetypeid);

				this.devicesOptions.sort((a, b) => {
					const [textA, numA] = sortKey(a.code);
					const [textB, numB] = sortKey(b.code);

					// First, compare the text parts
					if (textA < textB) return -1;
					if (textA > textB) return 1;

					// If the text parts are equal, compare the numeric parts
					return numA - numB;
				});
				this.pollutantsOptions.sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				});
				//this.devicesOptions = [this.selectAll].concat(this.devicesOptions);
				//this.pollutantsOptions = [this.selectAll].concat(this.pollutantsOptions);
				console.log('inputDatasource 2', this.devicesOptions, this.pollutantsOptions);
			} else {
				this.devicesOptions = [];
				this.pollutantsOptions = [];
				this.selectedDevices = [];
				this.selectedPollutants = [];
				this.disabledDevices = true;
			}

			/* else {
					this.devicesOptions = [this.selectAll].concat(
						this.devices.filter((device) => device.pmdatasourcetypeid == this. selectedDatasource.pmdatasourcetypeid)
					);
				} */

			/* console.log('inputDatasource', datasourcetype, this. selectedDatasource);
				const indexName = obtainIndexName(datasourcetype);
				console.log('info: indexName: ', indexName);
				if (indexName) {
					//this.obtainLastData(this.deviceObject.code);
					console.warn('realTimeElasticData index', this.realTimeElasticData, this.realTimeElasticData.length);
					if (this.realTimeElasticData.length === 0) {
						obtainRealTimeDatasource(datasourcetype, indexName, this.$store);
					}
				} else {
					this.$puiNotify.error(this.$t('common.noElasticIndex'));
				} */
		},
		inputDevice(e) {
			console.log('dynamic input', e, this.selectedDatasource, this.devicesOptions, this.selectedPollutants);
			/* if (Array.isArray(e)) {
				if (e[e.length - 1] == -1) {
					this.selectedDevices = [];
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
					this.devicesOptions.forEach((device) => this.selectedDevices.push(device));
				} else if (e[e.length - 1] == -2) {
					this.selectAll.name = this.$t('panels.device.select-all');
					this.selectAll.code = -1;
					this.selectedDevices = [];
				} else if (e.length == this.devicesOptions.length) {
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
				} else if (e.includes(-1)) {
					const deviceAll = this.devicesOptions.find((device) => device.code == -1);
					const index = this.selectedDevices.indexOf(deviceAll);
					if (index > -1) {
						this.selectedDevices.splice(index, 1);
					}
				}
			} */

			if (this.selectedDatasource && this.selectedDevices.length > 0 /* && this.selectedPollutants.length > 0 */) {
				const indexName = obtainIndexName(this.selectedDatasource);
				console.log('info: indexName: ', indexName);
				if (indexName) {
					//this.obtainLastData(this.deviceObject.code);
					let callFunction = false;
					this.selectedDevices.forEach((device) => {
						if (!this.realTimeElasticData[device.code]) {
							callFunction = true;
							console.log('callFunction', callFunction, device.code);
						}
					});
					console.warn('realTimeElasticData index', this.realTimeElasticData, this.realTimeElasticData.length);
					if (callFunction) {
						obtainRealTimeDatasource(this.selectedDatasource, indexName, this.$store, this.selectedPollutants, this.selectedDevices);
					}
				} else {
					this.$puiNotify.error(this.$t('common.noElasticIndex'));
				}
			}
		},
		getBreakPoint(length, breakpoint) {
			switch (breakpoint) {
				case 'xl':
					if (length == 1) return 12;
					if (length == 2) return 6;
					if (length == 3) return 4;
					if (length == 4) return 3;
					if (length >= 5) return 2;
					break;
				case 'lg':
					if (length == 1) return 12;
					if (length == 2) return 6;
					if (length == 3) return 4;
					if (length == 4) return 3;
					if (length >= 5) return 2;
					break;
				case 'md':
					if (length == 1) return 12;
					if (length == 2) return 6;
					if (length == 3) return 4;
					if (length >= 4) return 3;
					//if (length >= 5) return 2;
					break;
				case 'sm':
					if (length == 1) return 12;
					if (length == 2) return 6;
					if (length >= 3) return 4;
					//if (length >= 4) return 3;
					// if (length >= 5) return 2;
					break;
				case 'xs':
					if (length == 1) return 12;
					if (length == 2) return 6;
					if (length >= 3) return 4;
					// if (length == 4) return 3;
					//if (length >= 5) return 2;
					break;
			}
		},
		icon(selected, total) {
			if (selected == total) return 'fas fa-check-square';
			if (selected > 0 && selected < total) return 'fas fa-minus-square';
			return 'far fa-square';
		},
		toggleSelection(selectedProperty, optionsProperty, allSelected) {
			this.$nextTick(() => {
				if (allSelected) {
					this[selectedProperty] = [];
				} else {
					this[selectedProperty] = this[optionsProperty].slice();
				}
			});
		}
	}
};
</script>

<style lang="postcss">
.device-realtime {
	overflow: scroll;
}

.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>
