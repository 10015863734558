<template>
	<div class="d-flex flex-column devicesmappanel fill-height max-height-100">
		<v-container class="white pb-1">
			<v-row no-gutters justify="end">
				<v-col cols="auto">
					<v-tooltip left color="var(--gris-secondary)" transition="none">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="visible = true"
								depressed
								small
								:color="primaryColor"
								class="btn ma-0 ml-1 fs-regular color-white"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon size="18" :ripple="false">fas fa-chart-line</v-icon>
							</v-btn>
						</template>
						<span class="fs-sm">{{ this.$t('panels.device.advanced-datasource-tab') }}</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-container>
		<div class="d-flex flex-column py-3 px-3 bottomborder" ref="header">
			<div class="devices-title">{{ $t('panels.devicespanel.devices') }}</div>
			<div class="devices-subtitle fw-regular fs-sm">{{ $t('panels.devicespanel.subtitle') }}</div>
		</div>

		<div class="lists pb-0" style="min-height: 30%">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- Devices -->
				<div v-if="showDevices">
					<v-list-item class="px-3">
						<devices-list ref="deviceslist" />
					</v-list-item>
				</div>

				<v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)" :class="{ 'mt-2': isOpened.devices }" />
			</v-list>
		</div>
		<datasource-dialog-item :dialog.sync="visible" @update:dialog="visible = false">
			<template #action-button>
				<div class="d-flex flex-nowrap justify-end align-end mt-1">
					<!-- <div v-if="notificationsByType" class="d-flex align-center">
						<v-tooltip
							top
							transition="none"
							:color="notificationType.severitycolor + 'd9'"
							v-for="(notificationType, i) in notificationsByType"
							:key="i"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-chip color="white" class="px-2 mr-1" link pill small v-bind="attrs" v-on="on">
									<v-img :src="notificationType.urlbase + notificationType.iconroute" height="24" aspect-ratio="1" contain />
									<span class="ps-2 fs-regular fw-500">{{ notificationType.notifications.length }}</span></v-chip
								>
							</template>
							<span class="fs-regular">{{ notificationType.severityname }}</span>
						</v-tooltip>
					</div> -->
					<v-btn depressed small class="feature-btn pa-0 ml-2" @click="requestFullscreen" :color="primaryColor">
						<v-icon class="color-white" :class="!fullscreenDialog ? 'fak fa-line-zoom-framing' : 'fas fa-compress'" size="16"></v-icon>
					</v-btn>
					<v-btn
						style="max-width: 32px; min-width: 32px"
						class="ml-2 feature-btn color-white pa-0"
						small
						depressed
						:color="primaryColor"
						@click="closeDialog"
					>
						<v-icon class="fak fa-solid-close" style="font-size: 0.8rem !important"></v-icon>
					</v-btn>
				</div>
			</template>
		</datasource-dialog-item>
	</div>
</template>

<script>
import DevicesList from './DevicesList';
import DatasourceDialogItem from '@/components/map/sections/map/subcomponents/DatasourceDialogItem';
import constants from '@/utils/constants';
export default {
	name: 'DevicesMapPanel',
	data() {
		return {
			notificationsByType: null,
			visible: false,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	components: {
		DevicesList,
		DatasourceDialogItem
	},

	computed: {
		isOpened() {
			return this.$store.state.devicesPanel.isOpened;
		},
		showDevices() {
			return Object.keys(this.$store.getters.getDevices).length > 0;
			//Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL).length > 0;
		},
		fullscreenDialog: {
			get() {
				return this.$store.getters.getFullscreenDialog;
			},
			set(value) {
				this.$store.commit('setFullscreenDialog', value);
			}
		},
		selectedTabDatasource: {
			get() {
				return this.$store.getters.getSelectedTabDatasource;
			},
			set(value) {
				this.$store.commit('setSelectedTabDatasource', value);
			}
		}
	},

	created() {
		console.log('show devices: ' + this.showDevices);
		console.log(Object.keys(this.$store.getters.getDevices).length > 0);
		console.log(Object.keys(this.$store.getters.getDevices).length);
		console.log(this.$store.getters.getHasDevices);
		document.addEventListener('keyup', this.toggleEscapeDatasource);
	},
	mounted() {
		/* if (this.$store.getters.getNotifications && this.$store.getters.getNotifications.length > 0) {
			this.notificationsByType = this.groupNotificationsByType(this.$store.getters.getNotifications);
		} */
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.toggleEscapeDatasource);
	},
	methods: {
		groupNotificationsByType(notifications) {
			const object = notifications.reduce((r, { severityname, severitycolor, iconroute, urlbase, ...rest }) => {
				if (!r[severityname])
					r[severityname] = {
						severityname,
						severitycolor,
						iconroute,
						urlbase,
						notifications: [{ severityname, severitycolor, iconroute, urlbase, ...rest }]
					};
				else r[severityname].notifications.push({ severityname, severitycolor, iconroute, urlbase, ...rest });
				return r;
			}, {});
			//console.log('debug: group notif', object);
			return object;
		},
		requestFullscreen() {
			console.log('requestFullscreen', this.fullscreenDialog);
			this.fullscreenDialog = !this.fullscreenDialog;
			if (this.fullscreenDialog) {
				document.documentElement.style.setProperty('--datasource-height-dialog', 'calc(100% - 44px)');
			} else {
				document.documentElement.style.setProperty('--datasource-height-dialog', '80vh');
			}
		},
		closeDialog() {
			this.visible = false;
			this.fullscreenDialog = false;
			this.selectedTabDatasource = constants.FEATURE_TABS.REAL_TIME;
		},
		toggleEscapeDatasource(e) {
			console.log('toggleEscape', e.key, e.key == 'Escape', this.displayMode == 'dialog');
			if (e.key == 'Escape' && this.visible) this.closeDialog();
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.devicesmappanel {
	& .list-icon {
		color: var(--azul-primary-100);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: #000;
		}
	}

	& .devices-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}

	& .devices-subtitle {
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--gris-500);
		margin-top: 2px !important;
	}
}
.bottomborder {
	border-bottom: 1px solid var(--moderate);
}
</style>
