<template>
	<historic-chart-datasource :pollutants="pollutants" />
</template>

<script>
import HistoricChartDatasource from '@/components/echart/HistoricChartDatasource';

//import { lastData } from '@/api/databases_API';
// eslint-disable-next-line no-unused-vars
import { search, obtainIndexName } from '@/api/common';
//import { query_FirstDataByParameter } from '@/utils/queries';

export default {
	components: {
		HistoricChartDatasource
	},
	props: {},
	data() {
		return {
			tab: 0,
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],
			infoStartDate: this.startDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoStartDate'),
			infoEndDate: this.endDateDisabled ? this.$t('aqi.infoDateIntervalDisabled') : this.$t('aqi.infoEndDate'),
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters;
		},
		historicElasticData() {
			return this.$store.getters.getHistoricElasticData;
		},
		historicFormData() {
			return this.$store.getters.getHistoricStore;
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			},
			set(value) {
				this.$store.commit('setSelectedTabHistoric', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		}
	},
	watch: {},
	created() {
		console.warn('HistoricStore', this.$store.getters.getHistoricStore);
		if (this.displayLocation === 'card') {
			this.setupHistoricStore();
		}
	},
	mounted() {
		console.warn('mounted device historic', this.deviceObject, this.pollutants);

		/* var d = new Date();
		d.setDate(d.getDate() - 2);
		console.warn(d);
		console.warn(d.valueOf());

		const promise = lastData(
			obtainIndexName(this.deviceObject),
			query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym)
		);
		promise.then((elasticData) => {
			console.warn('Resultado HISTORIC ' + this.pollutants[0].elasticacronym + ' ' + this.deviceObject.code, elasticData);
			console.log('QUERY: ', query_FirstDataByParameter(this.deviceObject.code, this.pollutants[0].elasticacronym));

			if (typeof elasticData.message[0] == 'object') {
				let timestamp = elasticData.message[0]['_source']['dateObserved_timestamp'];
				let fecha = new Date(timestamp);
				console.log('date obtained', timestamp, fecha);
			}
		}); */
	},
	updated() {
		console.warn('TAB: ' + this.tab, this.selectedTabHistoric);
		console.warn(this.historicElasticData);
	},
	methods: {}
};
</script>

<style lang="postcss">
.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
.historic-datasource-chart {
	.v-select__selection {
		font-size: 14px !important;
	}
}
</style>
